// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
const isEqual = (value: any, other: any): boolean => {
  if (value === other) {
    return true
  }

  if (
    value == null ||
    other == null ||
    typeof value !== 'object' ||
    typeof other !== 'object'
  ) {
    return false
  }

  const keysA = Object.keys(value)
  const keysB = Object.keys(other)

  if (keysA.length !== keysB.length) {
    return false
  }

  for (const key of keysA) {
    if (!keysB.includes(key) || !isEqual(value[key], other[key])) {
      return false
    }
  }

  return true
}

export default isEqual
