// @noflow
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { countryCodeToPrivacyUrl } from '@/utils/countryCodeHelper'

import { Analytics } from '@/components/analytics/Analytics'
import type { CookiePreferences } from '@/components/cookie_consent/index'
import { BaseModalProps } from '@/components/shared/CookieConsent/Modals/CookieConsentModal'
import { actionToCookieConsent } from '@/components/shared/CookieConsent/helpers'
import { Action } from '@/components/shared/CookieConsent/types'

type StrictProps = {
  applyPreferences: (preferences: CookiePreferences) => void
  closeBanner?: never
}

type StrictModalProps = BaseModalProps

const copyContext = 'cookie_consent'

const StrictModal = ({
  shippingCountryCode,
  applyPreferences,
  setShowStrictModal,
  handleModalClose
}: StrictModalProps): JSX.Element => {
  const { t } = useTranslation('shared')

  const handleOnDeny = useCallback(() => {
    actionToCookieConsent({ action: Action.Deny, applyPreferences })
    handleModalClose()
    Analytics.track('Cookie Preferences Saved', {
      preferences: {
        marketing: false,
        analytics: false
      },
      // eslint-disable-next-line quotes
      context: "modal 'deny' button"
    })
  }, [applyPreferences, handleModalClose])

  // Switches to the Manage Modal
  const handleOnManage = useCallback(() => {
    setShowStrictModal(false)
  }, [setShowStrictModal])

  const handleOnAcceptAll = useCallback(() => {
    actionToCookieConsent({ action: Action.Accepted, applyPreferences })

    Analytics.track('Cookie Preferences Saved', {
      preferences: { marketing: true, analytics: true },
      // eslint-disable-next-line quotes
      context: "strict modal 'accept all' button"
    })

    handleModalClose()
  }, [applyPreferences, handleModalClose])

  return (
    <>
      <div className="cookie-modal__info">
        <h2>{t(`${copyContext}.banner.title`)}</h2>
        <p
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: t(`${copyContext}.banner.description_strict`, {
              privacyUrl: countryCodeToPrivacyUrl(shippingCountryCode)
            })
          }}
        />
      </div>
      <div className="btn-group">
        <button
          className="btn secondary"
          type="button"
          onClick={handleOnManage}
        >
          {t(`${copyContext}.banner.manage_btn`)}
        </button>
        <button className="btn secondary" type="button" onClick={handleOnDeny}>
          {t(`${copyContext}.banner.deny_btn`)}
        </button>
        <button
          id="cookie-banner-accept-button"
          data-testid="cookie-accept-strict-button"
          className="btn primary"
          type="button"
          onClick={handleOnAcceptAll}
        >
          {t(`${copyContext}.modal.accept_btn`)}
        </button>
      </div>
    </>
  )
}

export default StrictModal

export { StrictProps }
