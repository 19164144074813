// @noflow
// Config
import { format as formatDate } from 'date-fns'
import { de, enGB, fr, nl, nlBE, pl } from 'date-fns/locale'
import i18n from 'i18next'
import Pseudo from 'i18next-pseudo'
import Cookies from 'js-cookie'
import { initReactI18next } from 'react-i18next'

import * as StringHelper from '@/utils/StringHelper'
import { ordinality } from '@/utils/ordinal'
import * as Sentry from '@/utils/sentry'

import * as DEDEData from '../../../config/locales/de_DE.yml'
import * as ENData from '../../../config/locales/en.yml'
import * as FRData from '../../../config/locales/fr.yml'
import * as NLData from '../../../config/locales/nl.yml'
import * as NLBeData from '../../../config/locales/nl_BE.yml'
import * as PLData from '../../../config/locales/pl.yml'
import * as PLPLData from '../../../config/locales/pl_PL.yml'

// Due to needing to use snake_case elsewhere and i18next expecting kebab-case,
// we have the two different type declarations here to satisfy i18next's
// requirement whilst maintaining a consistent use of snake_case everywhere
// else possible

type Language = 'nl' | 'en' | 'nl_BE' | 'fr' | 'pl' | 'pl_PL' | 'de_DE'

type SupportedLanguage = 'en' | 'nl' | 'nl-BE' | 'fr' | 'pl' | 'pl-PL' | 'de-DE'

const supportedLanguages: Array<SupportedLanguage> = [
  'nl',
  'en',
  'nl-BE',
  'fr',
  'pl',
  'pl-PL',
  'de-DE'
]

const resources = {
  en: {
    app: ENData.default.en.app,
    dynamic: ENData.default.en.dynamic,
    surveys: ENData.default.en.surveys,
    learn_more_about_butternut:
      ENData.default.en.shared.learn_more_about_butternut,
    frequently_asked_questions:
      ENData.default.en.shared.frequently_asked_questions,
    reviews: ENData.default.en.reviews,
    account: ENData.default.en.account,
    homepage: ENData.default.en.homepage,
    home_page: ENData.default.en.home_page,
    contacts: ENData.default.en.contacts,
    our_products: ENData.default.en.our_products,
    our_story: ENData.default.en.our_story,
    making_our_meals: ENData.default.en.making_our_meals,
    wizard_flow: ENData.default.en.wizard_flow,
    self_resolution_flow: ENData.default.en.self_resolution_flow,
    customer_issue_management_flow:
      ENData.default.en.customer_issue_management_flow,
    plans_flow: ENData.default.en.plans_flow,
    simplified_plans_flow: ENData.default.en.simplified_plans_flow,
    ds_app_pay_on_signup_flow: ENData.default.en.ds_app_pay_on_signup_flow,
    checkout: ENData.default.en.checkout,
    thank_you: ENData.default.en.thank_you,
    models: ENData.default.en.models,
    product_categories: ENData.default.en.models.product_categories,
    post_signup_wizard: ENData.default.en.post_signup_wizard,
    dashboard: ENData.default.en.dashboard,
    shipping_countries: ENData.default.en.models.shipping_countries,
    calories_covered: ENData.default.en.shared.calories_covered,
    text_field: ENData.default.en.shared.text_field,
    footer: ENData.default.en.shared.footer,
    navigation: ENData.default.en.shared.navigation,
    unavailable_dates_card: ENData.default.en.shared.unavailable_dates_card,
    expert: ENData.default.en.shared.expert,
    paid_traffic_breeds: ENData.default.en.paid_traffic.breed_autocomplete,
    paid_traffic: ENData.default.en.paid_traffic,
    one_time_purchase_footer: ENData.default.en.shared.one_time_purchase_footer,
    order_summary: ENData.default.en.shared.order_summary,
    shared: ENData.default.en.shared,
    languages: ENData.default.en.models.languages,
    localisation_modal: ENData.default.en.shared.localisation_modal,
    plan_management: ENData.default.en.plan_management,
    onboarding_quiz: ENData.default.en.onboarding_quiz,
    product_modal: ENData.default.en.shared.product_modal,
    gql_errors: ENData.default.en.gql_errors,
    archive_reasons: ENData.default.en.archive_reasons,
    care: ENData.default.en.care,
    orders: ENData.default.en.orders,
    order: ENData.default.en.order,
    butternut_app: ENData.default.en.butternut_app,
    atoms: ENData.default.en.atoms,
    molecules: ENData.default.en.molecules,
    organisms: ENData.default.en.organisms,
    one_off_box_page: ENData.default.en.one_off_box_page,
    swap_and_save: ENData.default.en.swap_and_save,
    limited_offer_paused_page: ENData.default.en.limited_offer_paused_page,
    treatments_paused_page: ENData.default.en.treatments_paused_page,
    ribbon: ENData.default.en.ribbon,
    blog: ENData.default.en.blog,
    ambassadors: ENData.default.en.ambassadors
  },
  nl: {
    dynamic: NLData.default.nl.dynamic,
    surveys: NLData.default.nl.surveys,
    learn_more_about_butternut:
      NLData.default.nl.shared.learn_more_about_butternut,
    frequently_asked_questions:
      NLData.default.nl.shared.frequently_asked_questions,
    reviews: NLData.default.nl.reviews,
    account: NLData.default.nl.account,
    homepage: NLData.default.nl.homepage,
    home_page: NLData.default.nl.home_page,
    contacts: NLData.default.nl.contacts,
    our_products: NLData.default.nl.our_products,
    our_story: NLData.default.nl.our_story,
    making_our_meals: NLData.default.nl.making_our_meals,
    wizard_flow: NLData.default.nl.wizard_flow,
    self_resolution_flow: NLData.default.nl.self_resolution_flow,
    customer_issue_management_flow:
      NLData.default.nl.customer_issue_management_flow,
    plans_flow: NLData.default.nl.plans_flow,
    simplified_plans_flow: NLData.default.nl.simplified_plans_flow,
    checkout: NLData.default.nl.checkout,
    thank_you: NLData.default.nl.thank_you,
    models: NLData.default.nl.models,
    product_categories: NLData.default.nl.models.product_categories,
    post_signup_wizard: NLData.default.nl.post_signup_wizard,
    dashboard: NLData.default.nl.dashboard,
    shipping_countries: NLData.default.nl.models.shipping_countries,
    calories_covered: NLData.default.nl.shared.calories_covered,
    text_field: NLData.default.nl.shared.text_field,
    footer: NLData.default.nl.shared.footer,
    navigation: NLData.default.nl.shared.navigation,
    unavailable_dates_card: NLData.default.nl.shared.unavailable_dates_card,
    expert: NLData.default.nl.shared.expert,
    paid_traffic_breeds: NLData.default.nl.paid_traffic.breed_autocomplete,
    paid_traffic: NLData.default.nl.paid_traffic,
    one_time_purchase_footer: NLData.default.nl.shared.one_time_purchase_footer,
    order_summary: NLData.default.nl.shared.order_summary,
    shared: NLData.default.nl.shared,
    languages: NLData.default.nl.models.languages,
    localisation_modal: NLData.default.nl.shared.localisation_modal,
    plan_management: NLData.default.nl.plan_management,
    onboarding_quiz: NLData.default.nl.onboarding_quiz,
    product_modal: NLData.default.nl.shared.product_modal,
    gql_errors: NLData.default.nl.gql_errors,
    archive_reasons: NLData.default.nl.archive_reasons,
    care: NLData.default.nl.care,
    orders: NLData.default.nl.orders,
    order: NLData.default.nl.order,
    butternut_app: NLData.default.nl.butternut_app,
    atoms: NLData.default.nl.atoms,
    molecules: NLData.default.nl.molecules,
    organisms: NLData.default.nl.organisms,
    one_off_box_page: NLData.default.nl.one_off_box_page, // Note: New atomic OOB page
    swap_and_save: NLData.default.nl.swap_and_save,
    limited_offer_paused_page: NLData.default.nl.limited_offer_paused_page,
    treatments_paused_page: NLData.default.nl.treatments_paused_page,
    ribbon: NLData.default.nl.ribbon,
    ambassadors: NLData.default.nl.ambassadors
  },
  'nl-BE': {
    frequently_asked_questions:
      NLBeData.default.nl_BE.shared.frequently_asked_questions,
    account: NLBeData.default.nl_BE.account,
    homepage: NLBeData.default.nl_BE.homepage,
    home_page: NLBeData.default.nl_BE.home_page,
    contacts: NLBeData.default.nl_BE.contacts,
    our_story: NLBeData.default.nl_BE.our_story,
    wizard_flow: NLBeData.default.nl_BE.wizard_flow,
    plans_flow: NLBeData.default.nl_BE.plans_flow,
    checkout: NLBeData.default.nl_BE.checkout,
    models: NLBeData.default.nl_BE.models,
    post_signup_wizard: NLBeData.default.nl_BE.post_signup_wizard,
    text_field: NLBeData.default.nl_BE.shared.text_field,
    footer: NLBeData.default.nl_BE.shared.footer,
    paid_traffic: NLBeData.default.nl_BE.paid_traffic,
    plan_management: NLBeData.default.nl_BE.plan_management,
    order: NLBeData.default.nl_BE.order,
    molecules: NLBeData.default.nl_BE.molecules,
    organisms: NLBeData.default.nl_BE.organisms,
    limited_offer_paused_page: NLBeData.default.nl_BE.limited_offer_paused_page,
    ambassadors: NLBeData.default.nl_BE.ambassadors
  },
  pl: {
    dynamic: PLData.default.pl.dynamic,
    surveys: PLData.default.pl.surveys,
    learn_more_about_butternut:
      PLData.default.pl.shared.learn_more_about_butternut,
    frequently_asked_questions:
      PLData.default.pl.shared.frequently_asked_questions,
    reviews: PLData.default.pl.reviews,
    account: PLData.default.pl.account,
    homepage: PLData.default.pl.homepage,
    home_page: PLData.default.pl.home_page,
    contacts: PLData.default.pl.contacts,
    our_products: PLData.default.pl.our_products,
    our_story: PLData.default.pl.our_story,
    making_our_meals: PLData.default.pl.making_our_meals,
    wizard_flow: PLData.default.pl.wizard_flow,
    self_resolution_flow: PLData.default.pl.self_resolution_flow,
    customer_issue_management_flow:
      PLData.default.pl.customer_issue_management_flow,
    plans_flow: PLData.default.pl.plans_flow,
    simplified_plans_flow: PLData.default.pl.simplified_plans_flow,
    checkout: PLData.default.pl.checkout,
    thank_you: PLData.default.pl.thank_you,
    models: PLData.default.pl.models,
    product_categories: PLData.default.pl.models.product_categories,
    post_signup_wizard: PLData.default.pl.post_signup_wizard,
    dashboard: PLData.default.pl.dashboard,
    shipping_countries: PLData.default.pl.models.shipping_countries,
    calories_covered: PLData.default.pl.shared.calories_covered,
    text_field: PLData.default.pl.shared.text_field,
    footer: PLData.default.pl.shared.footer,
    navigation: PLData.default.pl.shared.navigation,
    unavailable_dates_card: PLData.default.pl.shared.unavailable_dates_card,
    expert: PLData.default.pl.shared.expert,
    paid_traffic_breeds: PLData.default.pl.paid_traffic.breed_autocomplete,
    paid_traffic: PLData.default.pl.paid_traffic,
    one_time_purchase_footer: PLData.default.pl.shared.one_time_purchase_footer,
    order_summary: PLData.default.pl.shared.order_summary,
    shared: PLData.default.pl.shared,
    languages: PLData.default.pl.models.languages,
    localisation_modal: PLData.default.pl.shared.localisation_modal,
    plan_management: PLData.default.pl.plan_management,
    onboarding_quiz: PLData.default.pl.onboarding_quiz,
    product_modal: PLData.default.pl.shared.product_modal,
    gql_errors: PLData.default.pl.gql_errors,
    archive_reasons: PLData.default.pl.archive_reasons,
    care: PLData.default.pl.care,
    orders: PLData.default.pl.orders,
    order: PLData.default.pl.order,
    butternut_app: PLData.default.pl.butternut_app,
    atoms: PLData.default.pl.atoms,
    molecules: PLData.default.pl.molecules,
    organisms: PLData.default.pl.organisms,
    one_off_box_page: PLData.default.pl.one_off_box_page, // Note: New atomic OOB page
    swap_and_save: PLData.default.pl.swap_and_save,
    limited_offer_paused_page: PLData.default.pl.limited_offer_paused_page,
    treatments_paused_page: PLData.default.pl.treatments_paused_page,
    ribbon: PLData.default.pl.ribbon,
    invoices: PLData.default.pl.invoices,
    ambassadors: PLData.default.pl.ambassadors
  },
  'pl-PL': {
    learn_more_about_butternut:
      PLPLData.default.pl_PL.shared.learn_more_about_butternut,
    frequently_asked_questions:
      PLPLData.default.pl_PL.shared.frequently_asked_questions,
    reviews: PLPLData.default.pl_PL.reviews,
    account: PLPLData.default.pl_PL.account,
    homepage: PLPLData.default.pl_PL.homepage,
    home_page: PLPLData.default.pl_PL.home_page,
    contacts: PLPLData.default.pl_PL.contacts,
    our_products: PLPLData.default.pl_PL.our_products,
    our_story: PLPLData.default.pl_PL.our_story,
    making_our_meals: PLPLData.default.pl_PL.making_our_meals,
    wizard_flow: PLPLData.default.pl_PL.wizard_flow,
    self_resolution_flow: PLPLData.default.pl_PL.self_resolution_flow,
    customer_issue_management_flow:
      PLPLData.default.pl_PL.customer_issue_management_flow,
    plans_flow: PLPLData.default.pl_PL.plans_flow,
    simplified_plans_flow: PLPLData.default.pl_PL.simplified_plans_flow,
    checkout: PLPLData.default.pl_PL.checkout,
    thank_you: PLPLData.default.pl_PL.thank_you,
    models: PLPLData.default.pl_PL.models,
    post_signup_wizard: PLPLData.default.pl_PL.post_signup_wizard,
    dashboard: PLPLData.default.pl_PL.dashboard,
    calories_covered: PLPLData.default.pl_PL.shared.calories_covered,
    text_field: PLPLData.default.pl_PL.shared.text_field,
    footer: PLPLData.default.pl_PL.shared.footer,
    navigation: PLPLData.default.pl_PL.shared.navigation,
    unavailable_dates_card:
      PLPLData.default.pl_PL.shared.unavailable_dates_card,
    expert: PLPLData.default.pl_PL.shared.expert,
    paid_traffic_breeds: PLPLData.default.pl_PL.paid_traffic.breed_autocomplete,
    paid_traffic: PLPLData.default.pl_PL.paid_traffic,
    one_time_purchase_footer:
      PLPLData.default.pl_PL.shared.one_time_purchase_footer,
    order_summary: PLPLData.default.pl_PL.shared.order_summary,
    shared: PLPLData.default.pl_PL.shared,
    localisation_modal: PLPLData.default.pl_PL.shared.localisation_modal,
    plan_management: PLPLData.default.pl_PL.plan_management,
    onboarding_quiz: PLPLData.default.pl_PL.onboarding_quiz,
    product_modal: PLPLData.default.pl_PL.shared.product_modal,
    gql_errors: PLPLData.default.pl_PL.gql_errors,
    archive_reasons: PLPLData.default.pl_PL.archive_reasons,
    care: PLPLData.default.pl_PL.care,
    orders: PLPLData.default.pl_PL.orders,
    order: PLPLData.default.pl_PL.order,
    butternut_app: PLPLData.default.pl_PL.butternut_app,
    atoms: PLPLData.default.pl_PL.atoms,
    molecules: PLPLData.default.pl_PL.molecules,
    organisms: PLPLData.default.pl_PL.organisms,
    one_off_box_page: PLPLData.default.pl_PL.one_off_box_page, // Note: New atomic OOB page
    swap_and_save: PLPLData.default.pl_PL.swap_and_save,
    limited_offer_paused_page: PLPLData.default.pl_PL.limited_offer_paused_page,
    treatments_paused_page: PLPLData.default.pl_PL.treatments_paused_page,
    invoices: PLPLData.default.pl_PL.invoices,
    ribbon: PLPLData.default.pl_PL.ribbon
  },
  fr: {
    dynamic: FRData.default.fr.dynamic,
    surveys: FRData.default.fr.surveys,
    learn_more_about_butternut:
      FRData.default.fr.shared.learn_more_about_butternut,
    frequently_asked_questions:
      FRData.default.fr.shared.frequently_asked_questions,
    reviews: FRData.default.fr.reviews,
    account: FRData.default.fr.account,
    homepage: FRData.default.fr.homepage,
    home_page: FRData.default.fr.home_page,
    contacts: FRData.default.fr.contacts,
    our_products: FRData.default.fr.our_products,
    our_story: FRData.default.fr.our_story,
    making_our_meals: FRData.default.fr.making_our_meals,
    wizard_flow: FRData.default.fr.wizard_flow,
    self_resolution_flow: FRData.default.fr.self_resolution_flow,
    customer_issue_management_flow:
      FRData.default.fr.customer_issue_management_flow,
    plans_flow: FRData.default.fr.plans_flow,
    simplified_plans_flow: FRData.default.fr.simplified_plans_flow,
    checkout: FRData.default.fr.checkout,
    thank_you: FRData.default.fr.thank_you,
    models: FRData.default.fr.models,
    product_categories: FRData.default.fr.models.product_categories,
    post_signup_wizard: FRData.default.fr.post_signup_wizard,
    dashboard: FRData.default.fr.dashboard,
    shipping_countries: FRData.default.fr.models.shipping_countries,
    calories_covered: FRData.default.fr.shared.calories_covered,
    text_field: FRData.default.fr.shared.text_field,
    footer: FRData.default.fr.shared.footer,
    navigation: FRData.default.fr.shared.navigation,
    unavailable_dates_card: FRData.default.fr.shared.unavailable_dates_card,
    expert: FRData.default.fr.shared.expert,
    paid_traffic_breeds: FRData.default.fr.paid_traffic.breed_autocomplete,
    paid_traffic: FRData.default.fr.paid_traffic,
    one_time_purchase_footer: FRData.default.fr.shared.one_time_purchase_footer,
    order_summary: FRData.default.fr.shared.order_summary,
    shared: FRData.default.fr.shared,
    languages: FRData.default.fr.models.languages,
    localisation_modal: FRData.default.fr.shared.localisation_modal,
    plan_management: FRData.default.fr.plan_management,
    onboarding_quiz: FRData.default.fr.onboarding_quiz,
    product_modal: FRData.default.fr.shared.product_modal,
    gql_errors: FRData.default.fr.gql_errors,
    archive_reasons: FRData.default.fr.archive_reasons,
    care: FRData.default.fr.care,
    orders: FRData.default.fr.orders,
    order: FRData.default.fr.order,
    butternut_app: FRData.default.fr.butternut_app,
    atoms: FRData.default.fr.atoms,
    molecules: FRData.default.fr.molecules,
    organisms: FRData.default.fr.organisms,
    one_off_box_page: FRData.default.fr.one_off_box_page, // Note: New atomic OOB page
    swap_and_save: FRData.default.fr.swap_and_save,
    limited_offer_paused_page: FRData.default.fr.limited_offer_paused_page,
    treatments_paused_page: FRData.default.fr.treatments_paused_page,
    ribbon: FRData.default.fr.ribbon,
    invoices: FRData.default.fr.invoices,
    ambassadors: FRData.default.fr.ambassadors
  },
  'de-DE': {
    dynamic: DEDEData.default.de_DE.dynamic,
    surveys: DEDEData.default.de_DE.surveys,
    learn_more_about_butternut:
      DEDEData.default.de_DE.shared.learn_more_about_butternut,
    frequently_asked_questions:
      DEDEData.default.de_DE.shared.frequently_asked_questions,
    reviews: DEDEData.default.de_DE.reviews,
    account: DEDEData.default.de_DE.account,
    homepage: DEDEData.default.de_DE.homepage,
    home_page: DEDEData.default.de_DE.home_page,
    contacts: DEDEData.default.de_DE.contacts,
    our_products: DEDEData.default.de_DE.our_products,
    our_story: DEDEData.default.de_DE.our_story,
    making_our_meals: DEDEData.default.de_DE.making_our_meals,
    wizard_flow: DEDEData.default.de_DE.wizard_flow,
    self_resolution_flow: DEDEData.default.de_DE.self_resolution_flow,
    customer_issue_management_flow:
      DEDEData.default.de_DE.customer_issue_management_flow,
    plans_flow: DEDEData.default.de_DE.plans_flow,
    simplified_plans_flow: DEDEData.default.de_DE.simplified_plans_flow,
    checkout: DEDEData.default.de_DE.checkout,
    thank_you: DEDEData.default.de_DE.thank_you,
    models: DEDEData.default.de_DE.models,
    product_categories: DEDEData.default.de_DE.models.product_categories,
    post_signup_wizard: DEDEData.default.de_DE.post_signup_wizard,
    dashboard: DEDEData.default.de_DE.dashboard,
    shipping_countries: DEDEData.default.de_DE.models.shipping_countries,
    calories_covered: DEDEData.default.de_DE.shared.calories_covered,
    text_field: DEDEData.default.de_DE.shared.text_field,
    footer: DEDEData.default.de_DE.shared.footer,
    navigation: DEDEData.default.de_DE.shared.navigation,
    unavailable_dates_card:
      DEDEData.default.de_DE.shared.unavailable_dates_card,
    expert: DEDEData.default.de_DE.shared.expert,
    paid_traffic_breeds: DEDEData.default.de_DE.paid_traffic.breed_autocomplete,
    paid_traffic: DEDEData.default.de_DE.paid_traffic,
    one_time_purchase_footer:
      DEDEData.default.de_DE.shared.one_time_purchase_footer,
    order_summary: DEDEData.default.de_DE.shared.order_summary,
    shared: DEDEData.default.de_DE.shared,
    languages: DEDEData.default.de_DE.models.languages,
    localisation_modal: DEDEData.default.de_DE.shared.localisation_modal,
    plan_management: DEDEData.default.de_DE.plan_management,
    onboarding_quiz: DEDEData.default.de_DE.onboarding_quiz,
    product_modal: DEDEData.default.de_DE.shared.product_modal,
    gql_errors: DEDEData.default.de_DE.gql_errors,
    archive_reasons: DEDEData.default.de_DE.archive_reasons,
    care: DEDEData.default.de_DE.care,
    orders: DEDEData.default.de_DE.orders,
    order: DEDEData.default.de_DE.order,
    butternut_app: DEDEData.default.de_DE.butternut_app,
    atoms: DEDEData.default.de_DE.atoms,
    molecules: DEDEData.default.de_DE.molecules,
    organisms: DEDEData.default.de_DE.organisms,
    one_off_box_page: DEDEData.default.de_DE.one_off_box_page, // Note: New atomic OOB page
    swap_and_save: DEDEData.default.de_DE.swap_and_save,
    limited_offer_paused_page: DEDEData.default.de_DE.limited_offer_paused_page,
    treatments_paused_page: DEDEData.default.de_DE.treatments_paused_page,
    ribbon: DEDEData.default.de_DE.ribbon,
    invoices: DEDEData.default.de_DE.invoices,
    ambassadors: DEDEData.default.de_DE.ambassadors
  }
}

const namespaceToPath = (ns: string): string => {
  switch (ns) {
    case 'learn_more_about_butternut':
      return 'shared.learn_more_about_butternut'
    case 'frequently_asked_questions':
      return 'shared.frequently_asked_questions'
    case 'reviews':
      return 'reviews'
    case 'account':
      return 'account'
    case 'homepage':
      return 'homepage'
    case 'home_page':
      return 'home_page'
    case 'wizard_flow':
      return 'wizard_flow'
    case 'self_resolution_flow':
      return 'self_resolution_flow'
    case 'customer_issue_management_flow':
      return 'customer_issue_management_flow'
    case 'plans_flow':
      return 'plans_flow'
    case 'simplified_plans_flow':
      return 'simplified_plans_flow'
    case 'checkout':
      return 'checkout'
    case 'ds_app_pay_on_signup_flow':
      return 'ds_app_pay_on_signup_flow'
    case 'thank_you':
      return 'thank_you'
    case 'contacts':
      return 'contacts'
    case 'our_products':
      return 'our_products'
    case 'our_story':
      return 'our_story'
    case 'making_our_meals':
      return 'making_our_meals'
    case 'models':
      return 'models'
    case 'product_categories':
      return 'models.product_categories'
    case 'dashboard':
      return 'dashboard'
    case 'post_signup_wizard':
      return 'post_signup_wizard'
    case 'shipping_countries':
      return 'models.shipping_countries'
    case 'calories_covered':
      return 'shared.calories_covered'
    case 'text_field':
      return 'shared.text_field'
    case 'footer':
      return 'shared.footer'
    case 'navigation':
      return 'shared.navigation'
    case 'unavailable_dates_card':
      return 'shared.unavailable_dates_card'
    case 'expert':
      return 'shared.expert'
    case 'paid_traffic_breeds':
      return 'paid_traffic.breed_autocomplete'
    case 'paid_traffic':
      return 'paid_traffic'
    case 'one_time_purchase_footer':
      return 'shared.one_time_purchase_footer'
    case 'order_summary':
      return 'shared.order_summary'
    case 'shared':
      return 'shared'
    case 'localisation_modal':
      return 'shared.localisation_modal'
    case 'plan_management':
      return 'plan_management'
    case 'onboarding_quiz':
      return 'onboarding_quiz'
    case 'product_modal':
      return 'shared.product_modal'
    case 'gql_errors':
      return 'gql_errors'
    case 'archive_reasons':
      return 'archive_reasons'
    case 'care':
      return 'care'
    case 'order':
      return 'order'
    case 'orders':
      return 'orders'
    case 'butternut_app':
      return 'butternut_app'
    case 'atoms':
      return 'atoms'
    case 'molecules':
      return 'molecules'
    case 'organisms':
      return 'organisms'
    case 'one_off_box_page':
      return 'one_off_box_page'
    case 'swap_and_save':
      return 'swap_and_save'
    case 'limited_offer_paused_page':
      return 'limited_offer_paused_page'
    case 'treatments_paused_page':
      return 'treatments_paused_page'
    case 'invoices':
      return 'invoices'
    case 'ribbon':
      return 'ribbon'
    case 'blog':
      return 'blog'
    case 'ambassadors':
      return 'ambassadors'
    default: {
      return ''
    }
  }
}
const dateLocale = (locale: Language): Locale => {
  switch (locale) {
    case 'en':
      return enGB
    case 'nl':
      return nl
    case 'nl_BE':
      return nlBE
    case 'fr':
      return fr
    case 'de_DE':
      return de
    case 'pl_PL':
    case 'pl':
      return pl
  }
}
const development = process.env.NODE_ENV === 'development'
const phraseBackendEnabled = Cookies.get('phraseapp_enabled') === 'true'
Cookies.remove('phraseapp_enabled')

const pseudolocaliseCookie = Cookies.get('pseudolocalise') === 'true'

// i18next expects kebab-case
const convertLanguageToKebabCase = (lng: Language): string => {
  switch (lng) {
    case 'nl_BE':
      return 'nl-BE'
    case 'pl_PL':
      return 'pl-PL'
    case 'de_DE':
      return 'de-DE'
    default:
      return lng
  }
}

const fallbackLanguage = (lng: Language): string | Array<string> => {
  switch (lng) {
    case 'pl_PL':
      return ['pl', 'en']
    case 'nl_BE':
      return ['nl', 'en']
    case 'nl':
    case 'fr':
    case 'de_DE':
    case 'pl':
    default:
      return 'en'
  }
}

const initI18n = (
  lng: Language,
  pseudolocalise = pseudolocaliseCookie,
  phraseEnabled = phraseBackendEnabled
): void => {
  if (i18n.isInitialized) return
  i18n.on('initialized', () => {
    document.dispatchEvent(
      new CustomEvent('translationInitialised', { detail: i18n })
    )
  })

  i18n
    .use(
      new Pseudo({
        enabled: pseudolocalise,
        languageToPseudo: 'en',
        letterMultiplier: 1
      })
    )
    .use(initReactI18next)
    .init({
      ns: [
        'ambassadors',
        'dynamic',
        'surveys',
        'learn_more_about_butternut',
        'frequently_asked_questions',
        'reviews',
        'account',
        'homepage',
        'home_page',
        'contacts',
        'thank_you',
        'our_products',
        'our_story',
        'making_our_meals',
        'wizard_flow',
        'self_resolution_flow',
        'customer_issue_management_flow',
        'plans_flow',
        'simplified_plans_flow',
        'checkout',
        'models',
        'product_categories',
        'dashboard',
        'post_signup_wizard',
        'ds_app_pay_on_signup_flow',
        'shipping_countries',
        'calories_covered',
        'text_field',
        'footer',
        'navigation',
        'unavailable_dates_card',
        'expert',
        'paid_traffic_breeds',
        'paid_traffic',
        'one_time_purchase_footer',
        'order_summary',
        'shared',
        'languages',
        'localisation_modal',
        'plan_management',
        'onboarding_quiz',
        'product_modal',
        'gql_errors',
        'archive_reasons',
        'care',
        'orders',
        'order',
        'butternut_app',
        'atoms',
        'molecules',
        'organisms',
        'one_off_box_page',
        'swap_and_save',
        'limited_offer_paused_page',
        'treatments_paused_page',
        'invoices',
        'ribbon'
      ],
      defaultNS: '',
      lng: convertLanguageToKebabCase(lng),
      react: {
        hashTransKey: () => null
      },
      fallbackLng: fallbackLanguage(lng),
      debug: development,
      resources: resources,
      saveMissing: true,
      supportedLngs: supportedLanguages,
      missingKeyHandler: (
        lngs: readonly string[],
        ns: string,
        key: string
      ): void => {
        if (development) {
          console.error(
            // eslint-disable-next-line i18next/no-literal-string
            `Missing key: ${key} in namespace: ${ns} for language(s): ${lngs
              .map((lng: string): string => lng)
              .join(', ')}`
          )
        } else if (ns !== 'dynamic') {
          Sentry.captureException(
            `Missing key: ${key} in namespace: ${ns} for language(s): ${lngs
              .map((lng: string): string => lng)
              .join(', ')}`
          )
        }
      },
      interpolation: {
        format: (value, format) => {
          // Ensure that the date is localised by updating its locale
          // ---
          switch (format) {
            case 'uppercase':
              return value.toUpperCase()
            case 'capitalise':
              return StringHelper.capitaliseFirstLetter(value)
            case 'ordinal':
              return ordinality(value, lng)
            case 'fullDate':
              return formatDate(value, 'P', { locale: dateLocale(lng) })
            case 'day':
              return formatDate(value, 'eeee', { locale: dateLocale(lng) })
            case 'month':
              return formatDate(value, 'MMMM', { locale: dateLocale(lng) })
            case 'year':
              return formatDate(value, 'yyyy', { locale: dateLocale(lng) })
            case 'shortenedMonth':
              return formatDate(value, 'MMM', { locale: dateLocale(lng) })
            case 'monthAndDate':
              return formatDate(value, 'MMMM do', { locale: dateLocale(lng) })
            case 'dateAndMonth':
              return formatDate(value, 'd MMMM', { locale: dateLocale(lng) })
            case 'dateNumber':
              return formatDate(value, 'd', { locale: dateLocale(lng) })
            case 'dayDateMonth':
              return formatDate(value, 'iiii, dd MMM', {
                locale: dateLocale(lng)
              })
            case 'dayDateLongMonth':
              return formatDate(value, 'iiii, dd MMMM', {
                locale: dateLocale(lng)
              })
            default:
              return value
          }
        },
        escapeValue: false // not needed for react as it escapes by default
      },
      postProcess: ['pseudo'],
      load: 'currentOnly'
    })
  if (phraseEnabled) {
    // the types the we see when phrase in context editor is enabled do not correspond to
    // expected value here
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    i18n.t = (key: string, global: any) => {
      let namespace = ''
      if (key.includes(':')) {
        // split up namespace and rest of key
        const fullKey = key.split(':')
        namespace = fullKey[0]
        key = fullKey[1]
      }
      if (global && global.context) {
        key = `${key}_${global.context}`
      }
      // The Phrase in context editor scans the page and identifies exposed keys that
      // look like this:
      // {{__phrase_${key}__}}
      // we attempted this transformation with their postProcessing library, however,
      // the library does not work with varying namespaces. The following solution
      // allows us to interpolate the keys while integrating the appropriate namespace
      // utilised in the I18n t function.
      // eslint-disable-next-line i18next/no-literal-string
      return `{{__phrase_${namespaceToPath(
        namespace || global.ns || ''
      )}.${key}__}}`
    }
  }
}

export default initI18n
export type { Language }
