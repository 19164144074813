// @noflow
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { countryCodeToPrivacyUrl } from '@/utils/countryCodeHelper'
import * as Sentry from '@/utils/sentry'

import { Analytics } from '@/components/analytics/Analytics'
import type { CookieConsent } from '@/components/cookie_consent'
import type { CookiePreferences } from '@/components/cookie_consent/index'
import {
  BaseModalProps,
  Variant
} from '@/components/shared/CookieConsent/Modals/CookieConsentModal'
import { actionToCookieConsent } from '@/components/shared/CookieConsent/helpers'
import { Action } from '@/components/shared/CookieConsent/types'

import { ensureNever } from '@/typescript/utils'

import { cookieConsentBannerState } from '../CookieConsent'

type ManageProps = {
  applyPreferences: (preferences: CookiePreferences) => void
  closeBanner: (arg0: boolean) => void
}

type ManageModalProps = BaseModalProps & {
  variant: Variant
}

type CookieTypeKey =
  | 'required_cookies'
  | 'functional_cookies'
  | 'marketing_cookies'

interface CookieCheckboxEvent extends HTMLInputElement {
  name: CookieTypeKey
}

const copyContext = 'cookie_consent'

const ManageModal = ({
  variant,
  shippingCountryCode,
  applyPreferences,
  setShowStrictModal,
  handleModalClose
}: ManageModalProps): JSX.Element => {
  const { t } = useTranslation('shared')

  const [functionalCookies, setFunctionalCookies] = useState(false)
  const [marketingCookies, setMarketingCookies] = useState(false)

  const cookieType: Array<{ key: CookieTypeKey; value: boolean }> = [
    // Users are not permitted to opt out of Required Cookies so this value will
    // always be true
    { key: 'required_cookies', value: true },
    { key: 'functional_cookies', value: functionalCookies },
    { key: 'marketing_cookies', value: marketingCookies }
  ]

  // These are part of Required Cookies, so are always allowed by default
  const defaultAllowedFunctionalities = [
    'save_progress',
    'remember_dog',
    'store_login'
  ]

  // Ticks all checkboxes, therefore allowing all cookies
  const handleOnAcceptAll = useCallback(() => {
    setFunctionalCookies(true)
    setMarketingCookies(true)
    actionToCookieConsent({ action: Action.Accepted, applyPreferences })

    Analytics.track('Cookie Preferences Saved', {
      preferences: {
        marketing: true,
        analytics: true
      },
      // eslint-disable-next-line quotes
      context: "modal 'accept all' button"
    })
    handleModalClose()
    cookieConsentBannerState({ visible: false })
  }, [applyPreferences, handleModalClose])

  const updateLocalStateFromLocalStorage = useCallback(() => {
    const cookieConsent = localStorage.getItem('cookieConsent')
    const cookieConsentJson: CookieConsent = cookieConsent
      ? JSON.parse(cookieConsent)
      : {}

    if (!cookieConsentJson || cookieConsentJson.state === 'banner-shown') {
      setFunctionalCookies(false)
      setMarketingCookies(false)

      return
    }

    cookieConsentJson.preferences.analytics
      ? setFunctionalCookies(true)
      : setFunctionalCookies(false)

    cookieConsentJson.preferences.marketing
      ? setMarketingCookies(true)
      : setMarketingCookies(false)
  }, [])

  const handleOnChange = useCallback(
    (e: ChangeEvent<CookieCheckboxEvent>) => {
      switch (e.target.name) {
        case 'functional_cookies':
          setFunctionalCookies(!functionalCookies)
          break
        case 'marketing_cookies':
          setMarketingCookies(!marketingCookies)
          break
        case 'required_cookies':
          Sentry.captureException(`Unknown paymentMethodIdentifierType`)
          break
        default:
          ensureNever(e.target.name)
      }
    },
    [functionalCookies, marketingCookies]
  )

  const handleOnCancel = useCallback(() => {
    // Resets the local component state back to it's
    // initial values
    updateLocalStateFromLocalStorage()

    if (variant === Variant.Strict) {
      // Switch to the Strict Modal
      setShowStrictModal(true)
    } else {
      handleModalClose()
    }
  }, [
    handleModalClose,
    setShowStrictModal,
    updateLocalStateFromLocalStorage,
    variant
  ])

  const handleOnSave = useCallback(() => {
    actionToCookieConsent({
      action: Action.Saved,
      preferences: {
        marketing: marketingCookies,
        analytics: functionalCookies
      },
      applyPreferences
    })
    Analytics.track('Cookie Preferences Saved', {
      preferences: {
        marketing: marketingCookies,
        analytics: functionalCookies
      },
      // eslint-disable-next-line quotes
      context: "modal 'save changes' button"
    })
    handleModalClose()
    cookieConsentBannerState({ visible: false })
  }, [marketingCookies, functionalCookies, applyPreferences, handleModalClose])

  useEffect(() => {
    updateLocalStateFromLocalStorage()
  }, [updateLocalStateFromLocalStorage])

  return (
    <>
      <div className="cookie-modal__info">
        <h2>{t(`${copyContext}.modal.title`)}</h2>
        <p
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: t(`${copyContext}.modal.subtitle`, {
              privacyUrl: countryCodeToPrivacyUrl(shippingCountryCode)
            })
          }}
        />
      </div>
      <div className="cookie-modal__control">
        <div className="cookie-modal__control__toggles">
          {cookieType.map(({ key, value }) => {
            return (
              <label
                className={`cookie-modal__control__toggles__toggle selected ${
                  key === 'required_cookies' ? 'required' : ''
                } ${key !== 'required_cookies' && value ? 'active' : ''}`}
                key={key}
              >
                <input
                  type="checkbox"
                  onChange={handleOnChange}
                  checked={value}
                  name={key}
                />
                <div>
                  <h4>{t(`${copyContext}.modal.${key}.heading`)}</h4>
                  <p>{t(`${copyContext}.modal.${key}.description`)}</p>
                </div>
              </label>
            )
          })}
        </div>
        <div className="cookie-modal__control__functionality">
          <div>
            <h3>{t(`${copyContext}.modal.functionality.heading.allowed`)}</h3>
            <ul>
              {defaultAllowedFunctionalities.map((allowedFunctionality) => {
                return (
                  <li key={allowedFunctionality}>
                    {t(
                      `${copyContext}.modal.functionality.${allowedFunctionality}`
                    )}
                  </li>
                )
              })}
              {functionalCookies && (
                <li>
                  {t(`${copyContext}.modal.functionality.site_consistency`)}
                </li>
              )}
              {marketingCookies && (
                <li>{t(`${copyContext}.modal.functionality.serve_ads`)}</li>
              )}
            </ul>
          </div>
          {(!functionalCookies || !marketingCookies) && (
            <div>
              <h3>
                {t(`${copyContext}.modal.functionality.heading.not_allowed`)}
              </h3>
              <ul>
                {!functionalCookies && (
                  <li>
                    {t(`${copyContext}.modal.functionality.site_consistency`)}
                  </li>
                )}
                {!marketingCookies && (
                  <li>{t(`${copyContext}.modal.functionality.serve_ads`)}</li>
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="btn-group">
        <button
          className="btn secondary"
          type="button"
          onClick={handleOnCancel}
        >
          {t(`${copyContext}.modal.cancel_btn`)}
        </button>
        <button className="btn secondary" type="button" onClick={handleOnSave}>
          {t(`${copyContext}.modal.save_btn`)}
        </button>
        <button
          className="btn primary"
          type="button"
          onClick={handleOnAcceptAll}
        >
          {t(`${copyContext}.modal.accept_btn`)}
        </button>
      </div>
    </>
  )
}

export default ManageModal
export { ManageProps }
