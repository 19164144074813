// @flow

export default function addMobileNavHelperListeners (): void {
  const hamburger = document.getElementById('hamburger')
  const sidebarMenu = document.getElementById('sidebar_menu')
  if (hamburger && sidebarMenu) {
    hamburger.addEventListener('click', (): void => {
      const display = window.getComputedStyle(sidebarMenu).getPropertyValue('display')
      if (display === 'none') {
        sidebarMenu.style.display = 'block'
        hamburger.classList.add('active')
      } else {
        sidebarMenu.style.display = 'none'
        hamburger.classList.remove('active')
      }
    })

    sidebarMenu.addEventListener('click', (e: Event): void => {
      // $FlowFixMe
      if (e.target.tagName === 'A') {
        sidebarMenu.style.display = 'none'
      }
    })
  }
}
